const scrollToSection = (anchor) => () => {
  const id = `${anchor}`;
  const element = document.getElementById(id);
  const navbar = document.getElementById("navbar");
  const menuBar = document.getElementById("menu-bar");

  const menuBarHeight = menuBar ? menuBar.offsetHeight : 0;
  const navbarHeight = navbar ? navbar.offsetHeight : 0;

  if (element) {
    const elementPosition =
      element.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = menuBarHeight
      ? elementPosition - menuBarHeight + 1
      : elementPosition - navbarHeight + 1;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export default scrollToSection;
