import React, { useEffect, useRef } from "react";

import { ReactComponent as EmailIcon } from "../assets/icons/email.svg";
import { Heading } from "../components";
import { ReactComponent as PinIcon } from "../assets/icons/pin.svg";
import { ReactComponent as TelephoneIcon } from "../assets/icons/telephone.svg";

const Contact = () => {
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const sectionsCurrent = sectionsRef.current;

    sectionsCurrent.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionsCurrent.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <section
      id="contact"
      className="flex flex-col"
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url(${require("../assets//backgrounds/bg-img-2.webp")}`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="wrapper">
        <Heading text="Kontakt" color="text-black" />

        <div className="grid gap-[2em] md:grid-cols-2">
          <div
            ref={(el) => (sectionsRef.current[0] = el)}
            className="grid place-items-center bg-navy p-[2em] slide-up-before"
          >
            <div className="mb-[2em] grid place-items-center">
              <img
                src={require("../assets/images/banner.webp")}
                alt="Banner"
                className="max-h-[6rem] w-full md:max-h-[15vh]"
              />
            </div>

            <div className="grid w-full auto-rows-[3em] font-heading font-bold md:w-auto">
              <div className="my-[0.75em] flex items-center gap-[0.5em] md:gap-[1em]">
                <div className="grid h-[1.5em] w-[1.5em] place-items-center">
                  <TelephoneIcon />
                </div>
                <p className="text-white">Tel. 664 555 003</p>
              </div>

              <div className="my-[0.75em] flex items-center gap-[0.5em] md:gap-[1em]">
                <div className="grid h-[1.5em] w-[1.5em] place-items-center">
                  <EmailIcon />
                </div>
                <p className="text-white">lis.galeriakostki@onet.pl</p>
              </div>

              <div className="my-[0.75em] flex items-center gap-[0.5em] md:gap-[1em]">
                <div className="grid h-[1.5em] w-[1.5em] place-items-center">
                  <PinIcon />
                </div>
                <p className="max-w-[11rem] text-white min-[420px]:max-w-full">
                  aleja św. Wojciecha 9, 06-500 Mława
                </p>
              </div>
            </div>
          </div>

          <div
            ref={(el) => (sectionsRef.current[1] = el)}
            className="grid w-full place-items-center bg-[#ffac0a] p-[2em] slide-up-before md:w-auto md:animation-delay-500"
          >
            <div className="mb-[2em] grid place-items-center">
              <img
                src={require("../assets/images/bruk-mar-logo.webp")}
                alt="Bruk-Mar Logo"
                className="max-h-[6rem] w-full md:max-h-[15vh]"
              />
            </div>

            <div className="grid w-full auto-rows-[3em] font-heading font-bold md:w-auto">
              <div className="my-[0.75em] flex items-center gap-[0.5em] md:gap-[1em]">
                <div className="grid h-[1.5em] w-[1.5em] place-items-center">
                  <TelephoneIcon fill="navy" />
                </div>
                <p className="text-white">Tel. 604 126 419</p>
              </div>

              <div className="my-[0.75em] flex items-center gap-[0.5em] md:gap-[1em]">
                <div className="grid h-[1.5em] w-[1.5em] place-items-center">
                  <EmailIcon fill="navy" />
                </div>

                <p className="text-white">lis.mariusz@wp.pl</p>
              </div>

              <div className="my-[0.75em] flex items-center gap-[0.5em] md:gap-[1em]">
                <div className="grid h-[1.5em] w-[1.5em] place-items-center">
                  <PinIcon fill="navy" />
                </div>
                <p className="text-white">Korboniec 41A, 06-500 Mława</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
