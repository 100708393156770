import React, { useEffect, useRef, useState } from "react";

const Heading = ({ text, color }) => {
  const headingRef = useRef(null);
  const [hrWidth, setHrWidth] = useState("0");
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    if (headingRef.current) {
      const headingWidth = headingRef.current.offsetWidth;
      setHrWidth(headingWidth + 64);
    }
  }, [dimensions]);

  return (
    <div className="mb-[2em] flex flex-col justify-center">
      <h3 ref={headingRef} className={`mb-[0.25em] ${color} w-fit`}>
        {text}
      </h3>
      <hr className="h-[0.25em] bg-red" style={{ width: hrWidth }} />
    </div>
  );
};

export default Heading;
