import React, { forwardRef, useEffect, useRef, useState } from "react";

import { Heading } from "../components";

const Products = () => {
  const Product = forwardRef(({ name, image, description, className }, ref) => {
    const headingRef = useRef(null);
    const divRef = useRef(null);
    const [translateY, setTranslateY] = useState(0);
    const [hovered, setHovered] = useState(false);

    const [dimensions, setDimensions] = useState({
      height: window.innerHeight,
      width: window.innerWidth,
    });

    useEffect(() => {
      function handleResize() {
        setDimensions({
          height: window.innerHeight,
          width: window.innerWidth,
        });
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    });

    useEffect(() => {
      if (headingRef.current && divRef.current) {
        const headingHeight = headingRef.current.offsetHeight;
        const divHeight = divRef.current.offsetHeight;

        const divStyles = window.getComputedStyle(divRef.current);
        const paddingTop = parseFloat(divStyles.paddingTop);

        setTranslateY(divHeight - headingHeight - paddingTop);
      }
    }, [dimensions]);

    return (
      <div
        ref={ref}
        className={`relative h-[6rem] md:h-[19rem] md:last-of-type:odd:col-span-2 ${className}`}
      >
        <img
          src={require(`../assets/images/${image}`)}
          alt={name}
          className="h-full w-full object-cover"
        />

        <div
          className="group absolute left-0 top-0 flex h-full w-full overflow-hidden transition-linear hover:bg-[rgba(0,0,0,0.6)]"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div
            ref={divRef}
            className="flex h-full flex-col justify-between p-[2em] transition-linear md:px-[4em] md:py-[2em]"
            style={{
              transform: hovered
                ? "translateY(0)"
                : `translateY(${translateY}px)`,
            }}
          >
            <div className="flex flex-col">
              <h3 ref={headingRef} className="pb-[0.25em] text-shadow">
                {name}
              </h3>
              <p className="hidden md:block">{description}</p>
            </div>
          </div>
        </div>
      </div>
    );
  });

  Product.displayName = "Product";

  const productsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const productsCurrent = productsRef.current;

    productsCurrent.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      productsCurrent.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <section id="products" className="relative">
      <div className="wrapper">
        <Heading text="Produkty" color="text-white" />

        <div className="grid gap-[1em] text-white md:grid-cols-2">
          <Product
            ref={(el) => (productsRef.current[0] = el)}
            className="slide-up-before"
            name="Kostka brukowa"
            image="paving-stones-img.webp"
            description="Bądź na czasie! Wybierz kostkę brukowa zgodnie ze swoimi
                  potrzebami"
          />

          <Product
            ref={(el) => (productsRef.current[1] = el)}
            className="slide-up-before md:md:animation-delay-500"
            name="Kamienie dekoracyjne"
            image="decorative-stones-img.webp"
            description="Nadaj swojemu otoczeniu inny wymiar!"
          />

          <Product
            ref={(el) => (productsRef.current[2] = el)}
            className="slide-up-before"
            name="Kruszywa"
            image="aggregate-img.webp"
            description="Dostarczamy wysokiej jakości kruszywa, które znajdują zastosowanie w budownictwie drogowym, budownictwie mieszkaniowym oraz w ogrodach. Nasze kruszywa są starannie selekcjonowane, co zapewnia ich wysoką jakość i trwałość."
          />

          <Product
            ref={(el) => (productsRef.current[3] = el)}
            className="slide-up-before md:md:animation-delay-500"
            name="Piasek dekoracyjny"
            image="decorative-sand-img.webp"
            description="Zapewniamy wysokiej jakości piasek dekoracyjny, idealny do ogrodów, alejek i przestrzeni publicznych. Nasz piasek jest starannie selekcjonowany, co zapewnia jego estetykę i trwałość, doskonale uzupełniając każdy projekt aranżacji przestrzeni."
          />

          <Product
            ref={(el) => (productsRef.current[4] = el)}
            className="slide-up-before"
            name="Ogrodzenia panelowe"
            image="panel-fencing-img.webp"
            description="Najlepszy Wybór Ogrodzeń w Twojej Okolicy! Szukasz solidnego,
                  estetycznego i trwałego ogrodzenia?"
          />

          <Product
            ref={(el) => (productsRef.current[5] = el)}
            className="slide-up-before md:md:animation-delay-500"
            name="Ogrodzenia modułowe"
            image="modular-fencing-img.webp"
            description="Najlepszy Wybór Ogrodzeń w Twojej Okolicy! Szukasz solidnego,
                  estetycznego i trwałego ogrodzenia?"
          />

          <Product
            ref={(el) => (productsRef.current[6] = el)}
            className="slide-up-before"
            name="Beton"
            image="concrete-img.webp"
            description="Oferujemy wysokiej jakości beton do budownictwa drogowego, mieszkaniowego i przemysłowego. Nasz starannie formułowany beton zapewnia wysoką wytrzymałość i trwałość, idealny do realizacji nawet najbardziej wymagających projektów."
          />

          <Product
            ref={(el) => (productsRef.current[7] = el)}
            className="slide-up-before md:md:animation-delay-500"
            name="Chemia"
            image="chemicals-img.webp"
            description="Dostarczamy wysokiej jakości środki chemiczne do pielęgnacji kostki brukowej, idealne do przestrzeni publicznych i prywatnych. Nasze produkty skutecznie czyszczą, impregnują i chronią kostkę, zapewniając jej trwałość i estetyczny wygląd. Oferujemy również preparaty do usuwania mchów, porostów i chwastów, co ułatwia konserwację i przedłuża żywotność nawierzchni."
          />
        </div>
      </div>

      <div className="absolute left-0 top-0 -z-10 grid h-full w-full grid-rows-2 overflow-hidden">
        <div
          className="absolute left-0 top-[-25vh] h-full w-full -skew-y-6"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 3, 39, 0.75), rgba(0, 3, 39, 0.75)), url(${require("../assets/backgrounds/bg-img-2.webp")}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            filter: "blur(2px)",
            boxShadow: "0 0 0 4px rgba(0, 0, 18, 0.7)",
          }}
        ></div>

        <div
          className="absolute left-0 top-[50%] h-full w-full -skew-y-6"
          style={{
            backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.75), rgba(255, 255, 255, 0.75)), url(${require("../assets//backgrounds/bg-img-1.webp")}`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
    </section>
  );
};

export default Products;
