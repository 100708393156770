import React, { useEffect, useRef, useState } from "react";

import { Link } from "react-router-dom";
import scrollToSection from "../functions/scrollToSection";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuRef = useRef(null);
  const menuBarRef = useRef(null);

  const handleToggleMenu = () => {
    setOpenMenu((prevState) => !prevState);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuBarRef.current &&
      !menuBarRef.current.contains(event.target)
    ) {
      setOpenMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav
      id="navbar"
      className="fixed top-0 z-40 flex w-full flex-col gap-[4em] p-[1.5em] md:sticky md:flex-row md:items-center md:justify-center md:gap-0 md:bg-navy md:px-[2em] md:py-[1em]"
    >
      <div
        ref={menuRef}
        className={`${openMenu ? "h-[500px] w-[620px] -translate-x-[60%] -translate-y-[30%] duration-500 min-[500px]:w-[670px] min-[700px]:w-[720px]" : "h-[14em] w-[12em] -translate-x-[40%] -translate-y-[60%] duration-500"} fixed left-0 top-0 z-40 rounded-full bg-navy transition-all ease-in-out md:hidden`}
      ></div>

      <button
        id="menu-bar"
        title="Menu"
        ref={menuBarRef}
        type="button"
        onClick={handleToggleMenu}
        className="z-50 flex flex-col gap-[0.4em] md:hidden"
      >
        <div
          className={`h-[0.25em] w-[2.5em] bg-white transition-linear ${openMenu && " translate-y-[9px] -rotate-[45deg]"}`}
        ></div>
        <div
          className={`h-[0.25em] w-[2.5em] bg-white transition-linear ${openMenu && "opacity-0"}`}
        ></div>
        <div
          className={`h-[0.25em] w-[2.5em] bg-white transition-linear ${openMenu && "-translate-y-[9px] rotate-[45deg]"}`}
        ></div>
      </button>

      <div
        className={`${openMenu ? "max-h-[15rem] duration-500" : "max-h-0 duration-300"} z-40 flex w-[80vw] flex-col overflow-hidden transition-all ease-in-out md:max-h-[15rem] md:flex-row`}
      >
        <ul className="nb-link flex w-full flex-col justify-evenly md:flex-row md:items-center">
          <li className="font-bold text-white transition-colors ease-linear hover:text-red max-md:mb-[0.5em]">
            <Link
              onClick={() => {
                scrollToSection("products")();
                setOpenMenu(false);
              }}
            >
              Produkty
            </Link>
          </li>
          <li className="font-bold text-white transition-colors ease-linear hover:text-red max-md:mb-[0.5em]">
            <Link
              onClick={() => {
                scrollToSection("partnerships")();
                setOpenMenu(false);
              }}
            >
              Współprace
            </Link>
          </li>
        </ul>
        <Link to="/" className="hidden md:inline">
          <img
            src={require("../assets/images/logo.webp")}
            alt="Logo"
            className="h-[3em] max-w-fit"
          />
        </Link>
        <ul className="nb-link flex w-full flex-col justify-evenly md:flex-row md:items-center">
          <li className="font-bold text-white transition-colors ease-linear hover:text-red max-md:mb-[0.5em]">
            <Link
              onClick={() => {
                scrollToSection("contact")();
                setOpenMenu(false);
              }}
            >
              Kontakt
            </Link>
          </li>
          <li className="font-bold text-white transition-colors ease-linear hover:text-red max-md:mb-[0.5em]">
            <Link
              onClick={() => {
                scrollToSection("faq")();
                setOpenMenu(false);
              }}
            >
              Pytania pomocnicze
            </Link>
          </li>
        </ul>
      </div>

      <div
        className={`fixed left-0 top-0 min-h-screen w-full bg-[rgba(0,0,0,0.5)] transition-linear md:hidden ${openMenu ? "opacity-100" : "opacity-0"} ${openMenu ? "pointer-events-auto" : "pointer-events-none"}`}
      ></div>
    </nav>
  );
};

export default Navbar;
