import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { object, string } from "yup";

import { ReactComponent as ChevronDownIcon } from "../assets/icons/chevron-down.svg";
import { Heading } from "../components";
import faq from "../assets/faq";

const Faq = () => {
  const [expandForm, setExpandForm] = useState(null);

  const validationSchema = object({
    name: string().required("Imię jest obowiązkowe"),
    email: string()
      .email("Niepoprawny adres e-mail")
      .required("E-mail jest obowiązkowy"),
    message: string().required("Wiadomość jest obowiązkowa"),
  });

  const QuestionGroup = forwardRef(({ element, className }, ref) => {
    const [expand, setExpand] = useState(null);

    return (
      <div
        ref={ref}
        className={`flex flex-col border-t-[1px] border-solid border-[rgba(19,23,76,0.4)] py-[1em] font-heading text-black ${className}`}
      >
        <button type="button" onClick={() => setExpand(!expand)}>
          <div className="mb-[0.75em] flex items-start justify-between gap-[1em]">
            <h4>{element?.question}</h4>
            <div className="flex items-center gap-[0.75em] ease-in">
              <h4 className="hidden font-normal transition-linear md:block">
                {expand ? "ZWIŃ" : "ROZWIŃ"}
              </h4>
              <ChevronDownIcon
                width="0.75em"
                className={`mb-1 ${expand && "rotate-180"}`}
              />
            </div>
          </div>
        </button>

        <p
          className={`${expand ? "max-h-[4rem]" : "max-h-0"} overflow-hidden transition-all duration-500 ease-in-out`}
        >
          {element?.answer}
        </p>
      </div>
    );
  });

  QuestionGroup.displayName = "QuestionGroup";

  const questionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const questionsCurrent = questionsRef.current;

    questionsCurrent.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      questionsCurrent.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <section id="faq" className="flex flex-col bg-gray">
      <div className="wrapper">
        <Heading text="Pytania pomocnicze" color="text-black" />
        <div className="flex flex-col">
          {faq?.map((element, index) => (
            <QuestionGroup
              element={element}
              key={element?.id}
              ref={(el) => (questionsRef.current[index] = el)}
              className="slide-up-before"
            />
          ))}
        </div>

        <div
          ref={(el) => (questionsRef.current[8] = el)}
          className="flex flex-col border-t-[1px] border-solid border-[rgba(19,23,76,0.4)] py-[1em] slide-up-before"
        >
          <button type="button" onClick={() => setExpandForm(!expandForm)}>
            <div className="mb-[0.75em] flex items-start justify-between gap-[1em]">
              <h4>
                Masz jakieś pytanie a nie znalazłeś odpowiedzi? Wypełnij
                formularz a na pewno odpowiemy!
              </h4>
              <div className="flex items-center gap-[0.75em]">
                <h4 className="hidden font-normal transition-linear md:block">
                  {expandForm ? "ZWIŃ" : "ROZWIŃ"}
                </h4>
                <ChevronDownIcon
                  width="0.75em"
                  className={`mb-1 ${expandForm && "rotate-180"}`}
                />
              </div>
            </div>
          </button>

          <div
            className={`${expandForm ? "max-h-[30rem]" : "max-h-0"} overflow-hidden transition-all duration-500 ease-in-out`}
          >
            <Formik
              initialValues={{ name: "", email: "", message: "" }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting }) => {
                console.log(values);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="flex flex-col items-center md:items-start">
                  <div className="my-[1.5em] grid w-full gap-[1em] gap-x-[2em] md:grid-cols-[min-content_auto]">
                    <div className="flex max-w-full flex-col gap-[0.5em] md:w-[15em]">
                      <label
                        htmlFor="name"
                        className="form-label font-heading font-bold text-dark-red"
                      >
                        Imię
                      </label>
                      <Field
                        type="text"
                        id="name"
                        name="name"
                        autoComplete="name"
                        className="rounded-lg border-[1px] border-solid border-navy p-[0.25em] text-black focus-visible:border-navy"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-navy"
                      />
                    </div>

                    <div className="row-span-2 flex max-w-full flex-col gap-[0.5em] md:w-[20em]">
                      <label
                        htmlFor="message"
                        className="form-label font-heading font-bold text-dark-red"
                      >
                        Wiadomość
                      </label>
                      <Field
                        as="textarea"
                        id="message"
                        name="message"
                        className="h-full rounded-lg border-[1px] border-solid border-navy p-[0.25em] text-black focus-visible:border-navy"
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="text-navy"
                      />
                    </div>

                    <div className="flex max-w-full flex-col gap-[0.5em] md:w-[15em]">
                      <label
                        htmlFor="email"
                        className="form-label font-heading font-bold text-dark-red"
                      >
                        E-mail
                      </label>
                      <Field
                        type="email"
                        id="email"
                        name="email"
                        autoComplete="email"
                        className="rounded-lg border-[1px] border-solid border-navy p-[0.25em] text-black focus-visible:border-navy"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-navy"
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="in-w-[6.5em] rounded-[1em] bg-dark-red px-[1em] py-[0.5em] text-center font-heading font-bold text-white transition-linear hover:bg-[#7b1919]"
                    disabled={isSubmitting}
                  >
                    Wyślij
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
