import { Footer } from ".";
import React from "react";

const Layout = ({ children }) => {
  return (
    <div className="flex min-h-screen flex-col">
      <div className="flex flex-col justify-center">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
