import React, { useEffect, useRef } from "react";

const Qualities = () => {
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const sectionsCurrent = sectionsRef.current;

    sectionsCurrent.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionsCurrent.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <section
      id="qualities"
      style={{
        backgroundImage: `linear-gradient(rgba(238, 238, 238, 0.95), rgba(238, 238, 238, 0.95)), url(${require("../assets//backgrounds/bg-img-2.webp")})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="wrapper">
        <div className="flex flex-col items-center justify-center text-center text-black">
          <h3 className="mb-[2em]">Dlaczego warto z nami współpracować?</h3>
          <div className="grid gap-[2em] lg:grid-cols-3">
            <div
              ref={(el) => (sectionsRef.current[0] = el)}
              className="group flex flex-col items-center gap-[0.5em] slide-up-before"
            >
              <div className="flex flex-col items-center transition-linear group-hover:scale-[1.1]">
                <img
                  src={require("../assets/images/quality.webp")}
                  alt="Jakość"
                  className="mb-[0.5em] h-full max-h-[3em] w-fit"
                />
                <p className="font-heading font-bold text-dark-red">
                  Certyfikowana Jakość
                </p>
              </div>
              <p>
                Zapewniamy produkty o najwyższych standardach jakości,
                potwierdzonych certyfikatami
              </p>
            </div>

            <div
              ref={(el) => (sectionsRef.current[1] = el)}
              className="group flex flex-col items-center gap-[0.5em] slide-up-before md:animation-delay-500"
            >
              <div className="flex flex-col items-center transition-linear group-hover:scale-[1.1]">
                <img
                  src={require("../assets/images/assembly.webp")}
                  alt="Montaż"
                  className="mb-[0.5em] h-full max-h-[3em] w-fit"
                />
                <p className="font-heading font-bold text-dark-red">
                  Profesjonalny Montaż
                </p>
              </div>
              <p>
                Zajmiemy się montażem każdego zakupionego produktu, dbając o
                jego precyzyjne wykonanie
              </p>
            </div>

            <div
              ref={(el) => (sectionsRef.current[3] = el)}
              className="group flex flex-col items-center gap-[0.5em] slide-up-before md:animation-delay-1000"
            >
              <div className="flex flex-col items-center transition-linear group-hover:scale-[1.1]">
                <img
                  src={require("../assets/images/price.webp")}
                  alt="Cena"
                  className="mb-[0.5em] h-full max-h-[3em] w-fit"
                />
                <p className="font-heading font-bold text-dark-red">
                  Konkurencyjne Ceny
                </p>
              </div>
              <p>
                Oferujemy produkty w bardzo atrakcyjnych cenach, zgodnych z
                cenami producenta
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Qualities;
