import * as React from "react";

import {
  BrukMar,
  Contact,
  Faq,
  Hero,
  Intro,
  Location,
  Partnerships,
  Products,
  Qualities,
} from "./sections";
import { Layout, Navbar } from "./components";

import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

function App() {
  return (
    <BrowserRouter>
      <Layout>
        <Helmet>
          <link
            rel="preload"
            href="../src/assets/backgrounds/hero-img.webp"
            as="image"
            type="image/webp"
            crossOrigin
          />
          <link
            rel="preload"
            href="../src/assets/fonts/k2d-v11-latin_latin-ext-bold.woff2"
            as="font"
            type="font/woff2"
            crossOrigin
          />
          <link
            rel="preload"
            href="../src/assets/fonts/inter-v18-latin_latin-ext-bold.woff2"
            as="font"
            type="font/woff2"
            crossOrigin
          />
        </Helmet>
        <Hero />
        <Navbar />
        <Intro />
        <BrukMar />
        <Qualities />
        <Products />
        <Partnerships />
        <Contact />
        <Location />
        <Faq />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
