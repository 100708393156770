const faq = [
  {
    id: 1,
    question: "Czy firma oferuje doradztwo projektowe i techniczne?",
    answer:
      "Tak, nasz doświadczony zespół specjalistów oferuje wsparcie i doradztwo na każdym etapie projektu, w tym doradztwo techniczne, pomoc w doborze odpowiednich materiałów oraz projektowanie nawierzchni.",
  },
  {
    id: 2,
    question: "Czy firma oferuje dostawę materiałów na teren klienta?",
    answer:
      "Tak, oferujemy dostawę materiałów na teren klienta w dogodnym dla niego terminie, zapewniając szybką i sprawna realizację zamówienia.",
  },
  {
    id: 3,
    question: "Czy firma udziela gwarancji na zakupioną kostkę brukową?",
    answer:
      "Tak, udzielamy gwarancji na zakupione produkty zgodnie z warunkami określonymi w naszej polityce gwarancyjnej.",
  },
  {
    id: 4,
    question:
      "Czy mogę samodzielnie wymieniać pojedyncze elementy kostki brukowej w razie potrzeby?",
    answer:
      "Tak, kostka brukowa jest łatwo wymienialna. W przypadku uszkodzenia pojedynczych elementów, można je łatwo usunąć i wymienić bez konieczności ingerencji w całość nawierzchni.",
  },
  {
    id: 5,
    question: "Czy kostka brukowa jest odporna na warunki atmosferyczne?",
    answer:
      "Kostka brukowa jest odporna na działanie warunków atmosferycznych, w tym mrozu, wilgoci oraz promieniowania UV, co gwarantuje jej trwałość i estetykę przez wiele lat.",
  },
  {
    id: 6,
    question:
      "Jakie są najlepsze praktyki dotyczące pielęgnacji świeżo wylanego betonu?",
    answer:
      "Najlepsze praktyki obejmują odpowiednie nawilżanie betonu, aby uniknąć zbyt szybkiego wysychania, oraz unikanie obciążania go przed całkowitym stwardnieniem. Ważne jest także zabezpieczenie betonu przed ekstremalnymi warunkami pogodowymi.",
  },
  {
    id: 7,
    question:
      "Jakie są najczęściej popełniane błędy przy stosowaniu chemii budowlanej i jak ich unikać?",
    answer:
      "Najczęstsze błędy to niewłaściwe proporcje mieszania, niedostateczne przygotowanie powierzchni, oraz niewłaściwe warunki aplikacji. Aby ich uniknąć, należy dokładnie przestrzegać instrukcji producenta i zapewnić odpowiednie warunki do aplikacji.",
  },
  {
    id: 8,
    question:
      "Jakie są zalety używania piasku dekoracyjnego w porównaniu do innych materiałów dekoracyjnych?",
    answer:
      "Piasek dekoracyjny jest wszechstronny, łatwy w utrzymaniu, odporny na działanie warunków atmosferycznych i oferuje szeroki zakres kolorów i faktur. Jest również często bardziej przystępny cenowo niż niektóre inne materiały dekoracyjne.",
  },
];

export default faq;
