import * as React from "react";

import { useEffect, useRef } from "react";

import { ReactComponent as EmailIcon } from "../assets/icons/email.svg";
import { Link } from "react-router-dom";
import { ReactComponent as LisText } from "../assets/icons/lis.svg";
import { ReactComponent as PinIcon } from "../assets/icons/pin.svg";
import { ReactComponent as TelephoneIcon } from "../assets/icons/telephone.svg";

const Footer = () => {
  const sectionsRef = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const sectionsCurrent = sectionsRef.current;

    sectionsCurrent.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionsCurrent.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  return (
    <footer id="footer" className="flex flex-col overflow-hidden bg-navy">
      <div className="flex justify-center p-[1.5em]">
        <div className="grid w-[90vw] lg:grid-cols-3">
          <div
            ref={(el) => (sectionsRef.current[0] = el)}
            className="flex items-center justify-center gap-[2em] slide-up-before"
          >
            <Link to="/">
              <img
                src={require("../assets/images/logo.webp")}
                alt="Logo"
                className="max-w-fit"
                style={{ width: "clamp(3em, 4.46vi + 2.23rem, 9em)" }}
              />
            </Link>
            <p className="font-heading font-bold text-white">
              Solidna jakość, trwała estetyka – wybierz kostkę brukową z naszej
              oferty!
            </p>
          </div>
          <div
            ref={(el) => (sectionsRef.current[1] = el)}
            className="hidden flex-col items-center justify-center gap-1 text-center font-bold text-white slide-up-before animation-delay-500 lg:flex"
          >
            <LisText className="mb-1 text-[5.5rem]" />
            <h3 className="leading-[1]">Galeria kostki brukowej</h3>
            <h4 className="border-b-2 border-solid border-red">
              Materiały budowlane
            </h4>
            <p>Tel. 664 555 003</p>
          </div>

          <div
            ref={(el) => (sectionsRef.current[2] = el)}
            className="hidden auto-rows-[3em] font-heading font-bold slide-up-before animation-delay-1000 lg:grid"
          >
            <div className="my-[0.75em] flex gap-[1em]">
              <div className="grid w-[2em] place-items-center">
                <TelephoneIcon />
              </div>
              <p className="text-white">Tel. 664 555 003</p>
            </div>

            <div className="my-[0.75em] flex gap-[1em]">
              <div className="grid w-[2em] place-items-center">
                <EmailIcon />
              </div>
              <p className="text-white">lis.galeriakostki@onet.pl</p>
            </div>

            <div className="my-[0.75em] flex gap-[1em]">
              <div className="grid w-[2em] place-items-center">
                <PinIcon />
              </div>
              <p className="text-white">aleja św. Wojciecha 9, 06-500 Mława</p>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white px-[1em] py-[0.25em]">
        <p className="text-right font-heading text-[0.5em] text-black">
          <span className="font-bold">Designed by</span> - michalbaginski@vp.pl
        </p>
      </div>
    </footer>
  );
};

export default Footer;
