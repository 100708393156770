import React, { useEffect, useRef } from "react";

import { ReactComponent as ArrowDownIcon } from "../assets/icons/arrow-down.svg";
import { Heading } from "../components";

const BrukMar = () => {
  const divRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-slide-up");
          }
        });
      },
      {
        threshold: 0.5,
      },
    );

    const divCurrent = divRef.current;

    if (divCurrent) {
      observer.observe(divCurrent);
    }

    return () => {
      if (divCurrent) {
        observer.unobserve(divCurrent);
      }
    };
  }, []);

  return (
    <section id="bruk-mar" className="relative">
      <div
        className="absolute left-0 top-0 h-full w-full md:w-[50%]"
        style={{
          background: `linear-gradient(rgba(0, 3, 39, 0.75), rgba(0, 3, 39, 0.75)), url(${require("../assets/backgrounds/bg-img-3.webp")}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div
        className="absolute right-0 top-0 hidden h-full w-[50%] md:block"
        style={{
          background: `url(${require("../assets/backgrounds/bg-img-4.webp")}`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>

      <div className="grid wrapper md:grid-cols-2">
        <div className="z-10 flex flex-col md:mr-[4em]">
          <Heading text="Bruk-Mar" color="text-white" />
          <div ref={divRef} className="flex flex-col slide-up-before">
            <p className="mb-[2em] text-white">
              Firma z ponad 18-letnim doświadczeniem w branży budowlanej,
              oferuje szeroki zakres usług, w tym profesjonalne układanie kostki
              brukowej, sprzedaż kruszyw i betonu, oraz usługi koparko-ładowarką
              i wywrotką. Gwarantujemy najwyższą jakość i estetykę naszych
              produktów i usług. Wyróżniamy się profesjonalizmem, dbałością o
              detale oraz indywidualnym podejściem do każdego klienta, co
              sprawia, że nasze realizacje są trwałe, funkcjonalne i estetyczne.
              Zapewniamy również szybki transport i terminową dostawę materiałów
              budowlanych na miejsce budowy.
            </p>

            <a
              href="https://www.facebook.com/brukmarmariuszlis"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="group flex items-center gap-[0.5em]">
                <p className="font-heading font-bold text-white">
                  Sprawdź naszą stronę
                </p>
                <ArrowDownIcon
                  className="-rotate-90 font-bold transition-linear group-hover:translate-x-[0.5em]"
                  height="1.5em"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrukMar;
